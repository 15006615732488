import "../assets/css/bootstrap.min.css";
import "../assets/css/ionicons.css";
import "../assets/css/plugins.css";
import "../assets/css/swiper.css";
import "../assets/css/slick.min.css";
import "../assets/css/lauis.css";
import "../assets/css/cerebrisans.css";
import "../assets/css/comic_sans_ms.css";
import "../assets/css/linea-font.css";
import "../assets/css/plyr.css";
import "../assets/css/animation.css";
import "../assets/css/animated-text.css";

import "../assets/scss/style.scss";

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Location } from "@reach/router";

import HeaderPT from "./header.pt";
import FooterPT from "./footer.pt";

import HeaderLP from "./headerLP.pt";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";

library.add(fal);

const LayoutPT = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQueryPT {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <Location>
                {({ location }) => {
                    if (location.pathname.split("/")[2] === "lp") {
                        return <HeaderLP siteTitle={data.site.siteMetadata.title} location={location} />;
                    } else {
                        return <HeaderPT siteTitle={data.site.siteMetadata.title} location={location} />;
                    }
                }}
            </Location>
            <main className="page-content">{children}</main>
            <FooterPT />
        </>
    );
};

LayoutPT.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LayoutPT;
